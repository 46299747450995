<template>
   <v-container>
      <crud
         api-path="customer"
         title="Pelanggan"
         :field="field"
         key-id="id"
         with-edit
         with-delete
      >
      </crud>
   </v-container>
</template>
<script>
import Crud from '@/components/crud2.vue'
export default {
   components: { Crud, },
   data: () => ({
      field: [
         { value: 'id', label: '#', ro: true, tbl: true },
         { value: 'name', label: 'Nama Pelanggan', tbl: true, rules: [v => !!v || 'Nama pelanggan harus diisi'], searchKey: true },
         { value: 'address', label: 'Alamat', tbl: true},
         { value: 'phone', label: 'HP/Telp', tbl: true},
         { value: 'disabled', label: 'Disable', tbl: true, opts: [{value: 0, text:'Tdk'}, {value: 1, text:'Ya'}]},
         { value: 'act', label: 'Aksi', ro: true, tbl: true, dis_sort: true }
      ]
   })
}
</script>